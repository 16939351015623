import { WiHumidity } from "react-icons/wi";

import HkHumidityList from "./HkHumidityList";

const resource = {
  label: "Humidity",
  list: HkHumidityList,
  icon: WiHumidity,
};

export default resource;
