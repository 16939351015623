import { GiExpense } from "react-icons/gi";

import ExpenseList from "./ExpenseList";
import ExpenseEdit from "./ExpenseEdit";
import ExpenseCreate from "./ExpenseCreate";

const resource = {
  label: "Expense",
  list: ExpenseList,
  create: ExpenseCreate,
  edit: ExpenseEdit,
  icon: GiExpense,
};

export default resource;
