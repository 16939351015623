import { GiTargetDummy } from "react-icons/gi";

import DummyList from "./DummyList";
import DummyEdit from "./DummyEdit";
import DummyCreate from "./DummyCreate";

const resource = {
  label: "Dummy List",
  list: DummyList,
  create: DummyCreate,
  edit: DummyEdit,
  icon: GiTargetDummy,
};

export default resource;
