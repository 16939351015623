import { useRecordContext } from "react-admin";
import { Chip } from "@mui/material";

const ArrayChipField = ({ source }) => {
  const _record = useRecordContext();
  if (!_record) return <div />;
  const _array = _record[source];
  if (typeof _array === "undefined" || _array === null || _array.length === 0)
    return <div />;
  return _array.map((_item) => <Chip label={_item} key={_item} />);
};

ArrayChipField.defaultProps = {
  addLabel: true,
  label: "List",
};

export default ArrayChipField;
