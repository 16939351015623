import React, { useMemo } from "react";
import { useGetIdentity, useGetList, usePermissions } from "react-admin";
import { useQuery } from "react-query";
import { useMediaQuery, useTheme } from "@mui/material";
import { subDays, startOfDay } from "date-fns";

import { acCanShow } from "../components/accessControl";
import inMemoryJWTManager from "../inMemoryJwt";

import Welcome from "./Welcome";
import TemperatureChart from "./TemperatureChart";
import RainfallChart from "./RainfallChart";
import LoadingSpinner from "./LoadingSpinner";
import ExpenseChart from "./ExpenseChart";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <div style={{ width: "1em" }} />;
const VerticalSpacer = () => <div style={{ height: "1em" }} />;

const ExpenseTitle = "Expenses (Last Month)";
const Expense3MonTitle = "Expenses (Last 3 Months)";

const DashboardReal = (props) => {
  const { profile } = props;
  const canShow = acCanShow(profile.permissions);
  const theme = useTheme();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);
  const threeMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 90), []);

  const token = inMemoryJWTManager.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const tempFilters = { record_time_gte: aMonthAgo.toISOString() };
  const tempParams = new URLSearchParams(tempFilters).toString();
  const tempUrl =
    process.env.REACT_APP_DATA_SOURCE + `/hktemp/daily?${tempParams}`;
  const { data: temperatureData, isLoading: temperatureLoading } = useQuery(
    "tempChartData",
    () =>
      fetch(tempUrl, {
        headers,
      }).then((res) => res.json())
  );

  const rainfallFilters = { start_time_gte: aMonthAgo.toISOString() };
  const rainfallParams = new URLSearchParams(rainfallFilters).toString();
  const rainfallUrl =
    process.env.REACT_APP_DATA_SOURCE + `/hkrainfall/daily?${rainfallParams}`;
  const { data: rainfallData, isLoading: rainfallLoading } = useQuery(
    "rainfallChartData",
    () =>
      fetch(rainfallUrl, {
        headers,
      }).then((res) => res.json())
  );

  const expenseFilter = { paid_at_gte: aMonthAgo.toISOString() };
  if (!canShow) expenseFilter["created_by"] = profile.userId;
  const { data: expenseData, isLoading: expenseLoading } = useGetList(
    "expenses",
    {
      filter: expenseFilter,
      sort: { field: "paid_at", order: "DESC" },
      pagination: { page: 1, perPage: -1 },
    }
  );

  const expense3MonFilter = { paid_at_gte: threeMonthAgo.toISOString() };
  if (!canShow) expense3MonFilter["created_by"] = profile.userId;
  const { data: expense3MonData, isLoading: expense3MonLoading } = useGetList(
    "expenses",
    {
      filter: expense3MonFilter,
      sort: { field: "paid_at", order: "DESC" },
      pagination: { page: 1, perPage: -1 },
    }
  );

  return isXSmall ? (
    <div>
      <div style={styles.flexColumn}>
        <Welcome />
        <VerticalSpacer />
        <TemperatureChart
          data={temperatureData}
          isLoading={temperatureLoading}
        />
        <VerticalSpacer />
        <RainfallChart data={rainfallData} isLoading={rainfallLoading} />
        <VerticalSpacer />
        <ExpenseChart
          title={ExpenseTitle}
          period={30}
          data={expenseData}
          isLoading={expenseLoading}
        />{" "}
        <ExpenseChart
          title={Expense3MonTitle}
          period={90}
          data={expense3MonData}
          isLoading={expense3MonLoading}
        />
      </div>
    </div>
  ) : isSmall ? (
    <div>
      <div style={styles.flexColumn}>
        <Welcome />
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <TemperatureChart
              data={temperatureData}
              isLoading={temperatureLoading}
            />
          </div>
          <div style={styles.rightCol}>
            <RainfallChart data={rainfallData} isLoading={rainfallLoading} />
          </div>
        </div>
        <VerticalSpacer />
        <div>
          <ExpenseChart
            title={ExpenseTitle}
            period={30}
            data={expenseData}
            isLoading={expenseLoading}
          />
        </div>
        <VerticalSpacer />
        <div>
          <ExpenseChart
            title={Expense3MonTitle}
            period={90}
            data={expense3MonData}
            isLoading={expense3MonLoading}
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      <Welcome />
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <TemperatureChart
            data={temperatureData}
            isLoading={temperatureLoading}
          />
        </div>
        <div style={styles.rightCol}>
          <RainfallChart data={rainfallData} isLoading={rainfallLoading} />
        </div>
      </div>
      <VerticalSpacer />
      <div>
        <ExpenseChart
          title={ExpenseTitle}
          period={30}
          data={expenseData}
          isLoading={expenseLoading}
        />
      </div>
      <VerticalSpacer />
      <div>
        <ExpenseChart
          title={Expense3MonTitle}
          period={90}
          data={expense3MonData}
          isLoading={expense3MonLoading}
        />
      </div>
    </>
  );
};

const Dashboard = () => {
  const { isLoading: isLoadingPermissions, permissions } = usePermissions();
  const { data: identity, isLoading: isLoadingIdentity } = useGetIdentity();

  if (isLoadingPermissions || isLoadingIdentity) return <LoadingSpinner />;
  return (
    <DashboardReal
      profile={{ userId: identity.id, permissions: permissions }}
    />
  );
};
export default Dashboard;
