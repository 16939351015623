import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  CheckboxGroupInput,
  useCreate,
  useNotify,
  useRedirect,
} from "react-admin";
import { Typography } from "@mui/material";

const { validateCode, validateName } = require("./roleValidate");
const { roleAccessChoice } = require("./roleType");

const RoleCreate = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (data) => {
    let role = { ...data };

    create(
      "roles",
      { data: role },
      {
        onSuccess: (data) => {
          notify("Role is created.", { type: "success" });
          redirect("/roles");
        },
        onError: (error) => {
          notify("Failed to create role.", { type: "error" });
        },
      }
    );
  };

  return (
    <Create title="Create role">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Create Role
        </Typography>
        <TextInput
          label="Code"
          source="code"
          validate={validateCode}
          autoFocus
          isRequired
          fullWidth
        />
        <TextInput
          label="Name"
          source="name"
          validate={validateName}
          isRequired
          fullWidth
        />
        <CheckboxGroupInput source="access" choices={roleAccessChoice} />
      </SimpleForm>
    </Create>
  );
};

export default RoleCreate;
