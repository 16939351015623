import { FaTemperatureHalf } from "react-icons/fa6";

import HkTempList from "./HkTempList";

const resource = {
  label: "Temperature",
  list: HkTempList,
  icon: FaTemperatureHalf,
};

export default resource;
