import React from "react";
import { FileField, useRecordContext } from "react-admin";

const MyFileField = (props) => {
  const { source, title } = props;
  const record = useRecordContext(props);

  return <FileField source={source} title={title} download={record.title} />;
};

export default MyFileField;
