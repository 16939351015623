import React, { useState } from "react";

import {
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { format, subDays, addDays } from "date-fns";
import LoadingSpinner from "./LoadingSpinner";
import NoData from "./NoData";

const lastDay = new Date();
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);

const dateFormatter = (date) => new Date(date).toLocaleDateString();

const getPlaceList = (data) => {
  if (!data || data.length === 0) return [];

  return data.reduce((acc, curr) => {
    if (!acc.includes(curr.place)) {
      const nextIndex = acc.findIndex((i) => curr.place < i);
      const index = nextIndex > -1 ? nextIndex : acc.length;
      acc.splice(index, 0, curr.place);
    }
    return acc;
  }, []);
};

const aggregRainfallByDay = (data, place) =>
  data
    .filter((item) => item.place === place)
    .reduce((acc, curr) => {
      const day = format(new Date(curr.date), "dd-MM-yyyy");
      if (!acc[day]) {
        acc[day] = 0;
      }
      acc[day] = curr.value || 0;
      return acc;
    }, {});

const getRainfallPerDay = (data, place) => {
  const daysWithRainfall = aggregRainfallByDay(data, place);
  return lastMonthDays.map((date) => ({
    date: date.getTime(),
    value: daysWithRainfall[format(new Date(date), "dd-MM-yyyy")] || 0,
  }));
};

const printPlaceMenu = (list) =>
  list.map((i) => (
    <MenuItem key={i} value={i}>
      {i}
    </MenuItem>
  ));

const RainfallChart = (props) => {
  const { data, isLoading } = props;
  const [place, setPlace] = useState("");
  const [chartData, setChartData] = useState([]);

  const placeList = getPlaceList(data);

  if (place === "" && placeList.length > 0) {
    setPlace(placeList[0]);
    setChartData(getRainfallPerDay(data, placeList[0]));
  }

  const handlePlaceChange = (e) => {
    setPlace(e.target.value);
    setChartData(getRainfallPerDay(data, e.target.value));
  };

  return (
    <Card>
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <CardHeader title="Total Rainfall" />
        </Box>
        {isLoading || placeList.length === 0 ? (
          <></>
        ) : (
          <Box ml={{ xs: 0, sm: "0.5em" }}>
            <Select
              sx={{ m: 1, width: 200 }}
              size="small"
              id="chart-rainfall-select"
              value={place}
              displayEmpty
              onChange={handlePlaceChange}
            >
              {printPlaceMenu(placeList)}
            </Select>
          </Box>
        )}
      </Box>
      <CardContent>
        <div style={{ width: "100%", height: 300 }}>
          {isLoading ? (
            <LoadingSpinner />
          ) : !data || data.length === 0 ? (
            <NoData />
          ) : (
            <ResponsiveContainer>
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  name="Date"
                  type="number"
                  scale="time"
                  padding={{ left: 30, right: 30 }}
                  domain={[
                    addDays(aMonthAgo, 1).getTime(),
                    new Date().getTime(),
                  ]}
                  tickFormatter={dateFormatter}
                />
                <YAxis
                  label={{
                    value: "mm",
                    angle: -90,
                    position: "insideLeft",
                    textAnchor: "middle",
                  }}
                />
                <Tooltip
                  formatter={(value) => `${value} mm`}
                  labelFormatter={(label) => dateFormatter(label)}
                />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" name="Rainfall" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default RainfallChart;
