import {
  Admin,
  fetchUtils,
  Resource,
  CustomRoutes,
  addRefreshAuthToDataProvider,
} from "react-admin";
import { Route } from "react-router-dom";
import jsonServerProvider from "ra-data-json-server";
import inMemoryJWTManager from "./inMemoryJwt";

import { authProvider, refreshAuth } from "./authProvider";
import { Login, Layout } from "./layout";
import { Dashboard } from "./dashboard";
import {
  acCanDelete,
  acCanCreate,
  acCanEdit,
  acCanShow,
} from "./components/accessControl";

// Resources related
import users from "./users";
import roles from "./roles";
import expenses from "./expenses";
import hktemp from "./hktemp";
import hkhumidity from "./hkhumidity";
import hklightning from "./hklightning";
import hkrainfall from "./hkrainfall";
import hkwarnsum from "./hkwarnsum";

import Profile from "./profile/Profile";

// Testing
import dummys from "./dummy";

const httpClient = (url, options = {}) => {
  const token = inMemoryJWTManager.getToken();
  if (token) {
    options.user = {
      authenticated: true,
      token: `Bearer ${token}`,
    };
  }

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
  process.env.REACT_APP_DATA_SOURCE,
  httpClient
);

const dataProviderWithRefresh = addRefreshAuthToDataProvider(
  dataProvider,
  refreshAuth
);
const App = () => {
  const CustomResource = (resourceName, permissions, obj) => {
    let op = {};
    if (acCanShow(permissions)) op["list"] = obj.list;
    if (acCanEdit(permissions)) op["edit"] = obj.edit;
    if (acCanCreate(permissions) || acCanDelete(permissions))
      op["create"] = obj.create;

    return <Resource name={resourceName} {...op} />;
  };

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProviderWithRefresh}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
    >
      {(permissions) => (
        <>
          {CustomResource("dummy", permissions, dummys)}
          <Resource
            name="expenses"
            list={expenses.list}
            edit={expenses.edit}
            create={expenses.create}
          />
          <Resource name="hktemp" list={hktemp.list} />
          <Resource name="hkrainfall" list={hkrainfall.list} />
          <Resource name="hkhumidity" list={hkhumidity.list} />
          <Resource name="hklightning" list={hklightning.list} />
          <Resource name="hkwarnsum" list={hkwarnsum.list} />
          <CustomRoutes>
            <Route path="/profile" element={<Profile />} />
          </CustomRoutes>
          {permissions.role === "admin" && (
            <>
              <Resource
                name="users"
                list={users.list}
                create={users.create}
                edit={users.edit}
              />
              <Resource
                name="roles"
                list={roles.list}
                create={roles.create}
                edit={roles.edit}
              />
            </>
          )}
        </>
      )}
    </Admin>
  );
};

export default App;
