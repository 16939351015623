import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  ExportButton,
  SearchInput,
  usePermissions
} from 'react-admin';
import { acCanEdit, acCanCreate, acCanDelete } from '../components/accessControl';

const dummyFilters = [
  <SearchInput source="q" alwaysOn />
];

const DummyList = () => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading)
    return (<></>)

  const bulkAction = acCanDelete(permissions);
  const canEdit = acCanEdit(permissions);
  const canCreate = acCanCreate(permissions);
  const canDelete = acCanDelete(permissions);

  const postRowClick = (id, resource, record) => {
    return (canEdit ? "edit" : "");
  }

  const ListActions = () => (
    <TopToolbar>
      {canCreate && (<CreateButton />)}
      <ExportButton />
    </TopToolbar>
  );

  return (
    <List
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
      filters={dummyFilters}
      actions={<ListActions />}
    >
      <Datagrid bulkActionButtons={bulkAction} rowClick={postRowClick}>
        <TextField source='name' />
        <TextField source='value' />
      </Datagrid>
    </List>
  )
};

export default DummyList;