import { BsCloudRainFill } from "react-icons/bs";

import HkRainfallList from "./HkRainfallList";

const resource = {
  label: "Rainfall",
  list: HkRainfallList,
  icon: BsCloudRainFill,
};

export default resource;
