import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  ExportButton,
  SearchInput,
} from "react-admin";

import ArrayChipField from "../components/ArrayChipField";

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const roleFilters = [<SearchInput source="q" alwaysOn />];

const RoleList = () => (
  <List
    sort={{ field: "code", order: "ASC" }}
    perPage={25}
    filters={roleFilters}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="code" />
      <TextField source="name" />
      <ArrayChipField source="access" sortable={false} />
    </Datagrid>
  </List>
);

export default RoleList;
