import * as React from "react";
import {
  Create,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
} from "react-admin";
import { Box, Typography } from "@mui/material";

const {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePassword,
} = require("./userValidate");

const UserCreate = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (data) => {
    let user = { ...data };
    delete user.rpassword;
    create(
      "users",
      { data: user },
      {
        onSuccess: (data) => {
          notify("User is created", { type: "success" });
          redirect("/users");
        },
        onError: (error) => {
          notify("Failed to create user", { type: "error" });
        },
      }
    );
  };

  const validateRPassword = (value, allValues) => {
    if (value !== allValues.password) return "Password not match.";
    return undefined;
  };

  return (
    <Create title="Create user">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Create User
        </Typography>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              label="First Name"
              source="firstname"
              validate={validateFirstName}
              autoFocus
              isRequired
              fullWidth
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              label="Last Name"
              source="lastname"
              validate={validateLastName}
              isRequired
              fullWidth
            />
          </Box>
        </Box>
        <TextInput
          label="Email"
          source="email"
          validate={validateEmail}
          isRequired
          fullWidth
        />
        <PasswordInput
          label="Password"
          source="password"
          validate={validatePassword}
          isRequired
          fullWidth
        />
        <PasswordInput
          label="Repeat Password"
          source="rpassword"
          validate={[validatePassword, validateRPassword]}
          isRequired
          fullWidth
        />
        <ReferenceInput source="role_id" reference="roles">
          <SelectInput label="Role" optionText="name" isRequired fullWidth />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
