import React from "react";

const NoData = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f3f3f3",
      width: "100%",
      height: "100%",
    }}
  >
    <span>No Data Available</span>
  </div>
);

export default NoData;
