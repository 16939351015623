import * as React from "react";
import LocalDateTimeField from "../components/LocalDateTimeField";
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  ExportButton,
  SearchInput,
} from "react-admin";

const ListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

const hkwarnsumFilters = [<SearchInput source="q" alwaysOn />];

const HkWarnsumList = () => (
  <List
    sort={{ field: "issue_time", order: "DESC" }}
    perPage={25}
    filters={hkwarnsumFilters}
    actions={<ListActions />}
  >
    <Datagrid bulkActionButtons={false}>
      <LocalDateTimeField source="issue_time" />
      <TextField source="name" />
      <TextField source="code" />
      <TextField source="status" />
      <LocalDateTimeField source="expire_time" />
      <LocalDateTimeField source="update_time" />
    </Datagrid>
  </List>
);

export default HkWarnsumList;
