import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { format, subDays, addDays } from "date-fns";

import LoadingSpinner from "./LoadingSpinner";
import NoData from "./NoData";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Legend,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  expenseCategoryChoices,
  expenseCurrencyChoices,
} from "../expenses/expenseType";

const ExpenseChart = (props) => {
  const { title, period, data, isLoading } = props;

  const [category, setCategory] = useState(expenseCategoryChoices[0].id);
  const [chartData, setChartData] = useState([]);

  const lastDay = new Date();
  const periodAgo = subDays(lastDay, period);
  const dateFormatter = (date) => new Date(date).toLocaleDateString();

  const aggregExpenseByDay = (data, category) => {
    const aggregExpenseList = data
      .filter((item) => category === 0 || item.category === category)
      .reduce((acc, curr) => {
        const day = format(new Date(curr.paid_at), "dd-MM-yyyy");
        if (!acc[day]) {
          acc[day] = {};
          acc[day].count = 0;
          expenseCurrencyChoices.map((item) => {
            return (acc[day][item.label] = 0);
          });
        }
        acc[day].count++;
        const currency = expenseCurrencyChoices.find(
          (item) => item.id === curr.currency
        );
        if (currency) acc[day][currency.label] += curr.total;
        return acc;
      }, {});
    return aggregExpenseList;
  };

  useEffect(() => {
    const getExpensePerDay = (data, user) => {
      const periodDays = Array.from({ length: period }, (_, i) =>
        subDays(new Date(), i)
      );
      const daysWithExpense = aggregExpenseByDay(data, category);
      return periodDays.map((date) => {
        const data = daysWithExpense[format(new Date(date), "dd-MM-yyyy")];
        const record = {
          date: date.getTime(),
          count: data ? data.count : 0,
        };
        expenseCurrencyChoices.forEach((currency) => {
          record[currency.label] = data ? data[currency.label] : 0;
        });
        return record;
      });
    };

    if (data && data.length > 0) {
      setChartData(getExpensePerDay(data, category));
    }
  }, [category, data, period]);

  const printCategoryMenu = (list) => {
    const choices = [];

    choices.push(
      list.map((i) => (
        <MenuItem key={i.id} value={i.id}>
          {i.label}
        </MenuItem>
      ))
    );
    choices.push(
      <MenuItem key={0} value={0}>
        All
      </MenuItem>
    );
    return choices;
  };

  const colorMap = [
    "#79d2b8",
    "#72c2e2",
    "#ffd275",
    "#fc91ad",
    "#b6c1ff",
    "#9a745f",
  ];
  const printYAxisGroup = () =>
    expenseCurrencyChoices.map((currency) => (
      <Bar
        key={currency.label}
        yAxisId="right"
        dataKey={currency.label}
        fill={colorMap[currency.id % 6]}
        name={currency.label}
      />
    ));

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  return (
    <Card>
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <CardHeader title={title} />
        </Box>
        <Box ml={{ xs: 0, sm: "0.5em" }}>
          <Select
            sx={{ m: 1, width: 200 }}
            size="small"
            id="chart-expense-select"
            value={category}
            displayEmpty
            onChange={handleCategoryChange}
          >
            {printCategoryMenu(expenseCategoryChoices)}
          </Select>
        </Box>
      </Box>
      <CardContent>
        <div style={{ width: "100%", height: 300 }}>
          {isLoading ? (
            <LoadingSpinner />
          ) : !data || data.length === 0 ? (
            <NoData />
          ) : (
            <ResponsiveContainer>
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  name="Date"
                  type="number"
                  scale="time"
                  padding={{ left: 30, right: 30 }}
                  domain={[
                    addDays(periodAgo, 1).getTime(),
                    new Date().getTime(),
                  ]}
                  tickFormatter={dateFormatter}
                />
                <YAxis
                  yAxisId="left"
                  orientation="left"
                  label={{
                    value: "#",
                    angle: -90,
                    position: "insideLeft",
                    textAnchor: "middle",
                  }}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: "$",
                    angle: -90,
                    position: "insideRight",
                    textAnchor: "middle",
                  }}
                />

                <Tooltip labelFormatter={(label) => dateFormatter(label)} />
                <Legend />
                <Bar
                  yAxisId="left"
                  dataKey="count"
                  fill="#8884d8"
                  name="Count"
                />
                {printYAxisGroup()}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ExpenseChart;
