import React from "react";
import { Typography } from "@mui/material";
import { useRecordContext } from "react-admin";

const LocalDateTimeField = (props) => {
  const { className, source } = props;
  const record = useRecordContext(props);
  let localTimeString;

  // Create a new Date object with the UTC time
  if (record[source] === null) {
    localTimeString = "";
  } else {
    const utcDate = new Date(record[source]);

    // Get the local time offset in minutes
    const localOffset = utcDate.getTimezoneOffset();

    // Calculate the local time by adding the offset
    const localTime = new Date(utcDate.getTime() - localOffset * 60000);

    // Format the local time as a string
    localTimeString = localTime.toLocaleString();
  }

  return (
    <Typography component="span" variant="body2" className={className}>
      {localTimeString}
    </Typography>
  );
};

export default LocalDateTimeField;
