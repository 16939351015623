const expenseCategoryChoices = [
  { id: 1, label: "Meal" },
  { id: 2, label: "Transport" },
  { id: 3, label: "Medical" },
  { id: 4, label: "Other" },
];

const expensePaymentChoices = [
  { id: 1, label: "Cash" },
  { id: 2, label: "Visa / Master" },
  { id: 3, label: "Cheque" },
  { id: 4, label: "Other" },
];

const expenseCurrencyChoices = [
  { id: 1, label: "HKD" },
  { id: 2, label: "TWD" },
  { id: 3, label: "CNY" },
  { id: 4, label: "USD" },
  { id: 5, label: "EUR" },
  { id: 6, label: "Other" },
];

const expenseStatusChoices = [
  { id: 1, label: "Pending" },
  { id: 2, label: "Approved" },
  { id: 3, label: "Rejected" },
];

const expenseIsApproved = function (status) {
  const item = expenseStatusChoices.find((e) => e.id === status);
  return item ? true : false;
};

export {
  expenseCategoryChoices,
  expensePaymentChoices,
  expenseCurrencyChoices,
  expenseStatusChoices,
  expenseIsApproved,
};
