import React from "react";
import { Box, Card, Button, Typography } from "@mui/material";

const Welcome = () => {
  return (
    <Card
      sx={{
        background: "#6f4ceb",
        color: "#fff",
        padding: "20px",
        marginTop: 2,
        marginBottom: "1em",
      }}
    >
      <Box
        display="flex"
        height="4em"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Welcome to Management System
        </Typography>
      </Box>
    </Card>
  );
};

export default Welcome;
