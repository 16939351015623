import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  useCreate,
  useNotify,
  usePermissions,
  useRedirect,
} from "react-admin";
import { Box, Typography } from "@mui/material";

const { validateFirstName } = require("../users/userValidate");

const DummyCreate = () => {
  const { isLoading, permissions } = usePermissions();
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  if (isLoading) return <>Loading...</>;

  const handleSubmit = (data) => {
    create(
      "dummy",
      { data: data },
      {
        onSuccess: (data) => {
          notify("Record is created", { type: "success" });
          redirect("/dummy");
        },
        onError: (error) => {
          notify("Failed to create record", { type: "error" });
        },
      }
    );
  };

  return (
    <Create title="Create Dummy Table">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Create Dummy Record
        </Typography>
        <TextInput
          label="Name"
          source="name"
          validate={validateFirstName}
          autoFocus
          isRequired
          fullWidth
        />
        <TextInput
          label="Value"
          source="value"
          validate={validateFirstName}
          isRequired
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default DummyCreate;
