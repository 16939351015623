import * as React from "react";
import LocalDateTimeField from "../components/LocalDateTimeField";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  TopToolbar,
  ExportButton,
  SearchInput,
} from "react-admin";

const ListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

const hkrainfallFilters = [<SearchInput source="q" alwaysOn />];

const HkRainfallList = () => (
  <List
    sort={{ field: "start_time", order: "DESC" }}
    perPage={25}
    filters={hkrainfallFilters}
    actions={<ListActions />}
  >
    <Datagrid bulkActionButtons={false}>
      <LocalDateTimeField source="start_time" />
      <LocalDateTimeField source="end_time" />
      <TextField source="place" />
      <NumberField source="min" />
      <NumberField source="max" />
    </Datagrid>
  </List>
);

export default HkRainfallList;
