import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  usePermissions,
  useUpdate,
  useRedirect,
  useNotify,
  Toolbar,
  DeleteButton,
  SaveButton,
} from "react-admin";
import { Typography } from "@mui/material";
import { acCanDelete } from "../components/accessControl";

const { validateFirstName } = require("../users/userValidate");

const DummyEdit = () => {
  const { isLoading, permissions } = usePermissions();

  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();

  if (isLoading) return <></>;

  const handleSubmit = (data) => {
    update(
      "dummy",
      {
        id: data.id,
        data: data,
      },
      {
        onSuccess: (data) => {
          notify("Update success", { type: "success" });
          redirect("/dummy");
        },
        onError: (error) => {
          notify("Update failed", { type: "error" });
        },
      }
    );
  };

  const DummyEditToolbar = (props) => (
    <Toolbar
      {...props}
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <SaveButton />
      {acCanDelete(permissions) ? <DeleteButton /> : <></>}
    </Toolbar>
  );

  return (
    <Edit title="Edit Dummy Table">
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={<DummyEditToolbar />}
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Edit Dummy Table
        </Typography>
        <TextInput
          label="Name"
          source="name"
          validate={validateFirstName}
          autoFocus
          isRequired
          fullWidth
        />
        <TextInput
          label="Value"
          source="value"
          validate={validateFirstName}
          isRequired
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default DummyEdit;
