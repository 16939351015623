import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  CheckboxGroupInput,
  useUpdate,
  useRedirect,
  useNotify,
} from "react-admin";
import { Typography } from "@mui/material";

const { validateCode, validateName } = require("./roleValidate");
const { roleAccessChoice } = require("./roleType");

const RoleEdit = () => {
  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();

  const handleSubmit = (data) => {
    let newData = { ...data };
    let newAccess = [];

    if (data.access.includes("read")) newAccess.push("read");
    if (data.access.includes("edit")) newAccess.push("edit");
    if (data.access.includes("create")) newAccess.push("create");
    if (data.access.includes("delete")) newAccess.push("delete");
    newData.access = newAccess;

    update(
      "roles",
      {
        id: newData.id,
        data: newData,
      },
      {
        onSuccess: (data) => {
          notify("Update success.", { type: "success" });
          redirect("/roles");
        },
        onError: (error) => {
          notify("Update failed.", { type: "error" });
        },
      }
    );
  };

  return (
    <Edit title="Edit Role">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Edit Role
        </Typography>
        <TextInput
          label="Code"
          source="code"
          validate={validateCode}
          autoFocus
          isRequired
          fullWidth
        />
        <TextInput
          label="Name"
          source="name"
          validate={validateName}
          isRequired
          fullWidth
        />
        <CheckboxGroupInput source="access" choices={roleAccessChoice} />
      </SimpleForm>
    </Edit>
  );
};

export default RoleEdit;
