import { GrStatusWarning } from "react-icons/gr";

import HkWarnsumList from "./HkWarnsumList";

const resource = {
  label: "Warning",
  list: HkWarnsumList,
  icon: GrStatusWarning,
};

export default resource;
