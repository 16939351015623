import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  DateInput,
  NumberInput,
  FileInput,
  useCreate,
  useNotify,
  useRedirect,
  required,
  minValue,
  useGetIdentity,
} from "react-admin";
import { Box, Typography } from "@mui/material";

import {
  expenseCategoryChoices,
  expenseCurrencyChoices,
  expensePaymentChoices,
} from "./expenseType";
import { fileUploadWithRefresh } from "../components/FileUploadWithRefresh";
import MyFileField from "../components/MyFileField";

const ExpenseCreate = () => {
  const { data: identity, isLoading: isLoadingIdentity } = useGetIdentity();
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  if (isLoadingIdentity) return <></>;

  const handleSubmit = async (data) => {
    let expense = { ...data };

    // Mark as 'Pending'
    expense["status"] = 1;
    expense["created_by"] = userId;

    // Upload receipt
    if (expense.receipt) {
      const receipt = expense.receipt;
      const formData = new FormData();
      formData.append("file", receipt.rawFile); // Add the file to the form data

      const uploadRes = await fileUploadWithRefresh(formData);
      if (uploadRes.status < 200 || uploadRes.status >= 300)
        return notify("Failed to upload receipt", { type: "error" });

      delete receipt.rawFile;
      delete receipt.src;
      receipt.path = uploadRes.path;
    }

    create(
      "expenses",
      { data: expense },
      {
        onSuccess: (data) => {
          notify("Expense is created", { type: "success" });
          redirect("/expenses");
        },
        onError: (data) => {
          notify("Failed to create expense", { type: "error" });
        },
      }
    );
  };

  const renderRequester = (choice) => `${choice.firstname} ${choice.lastname}`;
  const { id: userId } = identity;

  return (
    <Create title="Create expense">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Create Expense
        </Typography>
        <Box display={{ sx: "block", sm: "flex", width: "100%" }}></Box>
        <SelectInput
          label="Category"
          source="category"
          choices={expenseCategoryChoices}
          optionText="label"
          fullWidth
          validate={[required()]}
        />
        <SelectInput
          label="Payment"
          source="payment"
          choices={expensePaymentChoices}
          optionText="label"
          fullWidth
          validate={[required()]}
        />
        <DateInput source="paid_at" fullWidth validate={[required()]} />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              label="Currency"
              source="currency"
              choices={expenseCurrencyChoices}
              optionText="label"
              fullWidth
              validate={[required()]}
            />
          </Box>
          <Box flex={3} ml={{ xs: 0, sm: "0.5em" }}>
            <NumberInput
              label="Total"
              source="total"
              min={0}
              fullWidth
              validate={[required(), minValue(0)]}
            />
          </Box>
        </Box>
        <NumberInput
          label="Total (HKD)"
          source="amount"
          min={0}
          fullWidth
          validate={[minValue(0)]}
        />
        <FileInput label="Receipt" source="receipt">
          <MyFileField />
        </FileInput>
        <TextInput source="note" multiline fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default ExpenseCreate;
