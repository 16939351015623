import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  DateInput,
  NumberInput,
  FileInput,
  ReferenceInput,
  useUpdate,
  useNotify,
  useRedirect,
  required,
  minValue,
  usePermissions,
  useGetIdentity,
  useRecordContext,
} from "react-admin";
import { Box, Typography } from "@mui/material";

import {
  expenseStatusChoices,
  expenseCategoryChoices,
  expenseCurrencyChoices,
  expensePaymentChoices,
} from "./expenseType";

import { acCanEdit } from "../components/accessControl";
import { fileUploadWithRefresh } from "../components/FileUploadWithRefresh";
import MyFileField from "../components/MyFileField";

const ExpenseEditReal = (props) => {
  const { permissions, identity } = props;

  const { id: userId } = identity;
  const canEdit = acCanEdit(permissions);

  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async (data) => {
    let expense = { ...data };

    // Upload receipt
    if (expense.receipt && expense.receipt.rawFile) {
      const receipt = expense.receipt;
      const formData = new FormData();
      formData.append("file", receipt.rawFile); // Add the file to the form data

      const uploadRes = await fileUploadWithRefresh(formData);
      if (uploadRes.status < 200 || uploadRes.status >= 300)
        return notify("Failed to upload receipt", { type: "error" });

      delete receipt.rawFile;
      receipt.path = uploadRes.path;
    }

    if (expense?.receipt?.src) delete expense.receipt.src;

    update(
      "expenses",
      { id: data.id, data: expense },
      {
        onSuccess: (data) => {
          notify("Expense is updated", { type: "success" });
          redirect("/expenses");
        },
        onError: (data) => {
          notify("Failed to update expense", { type: "error" });
        },
      }
    );
  };

  const renderCreatedBy = (choice) => `${choice.firstname} ${choice.lastname}`;

  const EditContent = () => {
    const record = useRecordContext();

    if (record.path && record.title) {
      record["receipt"] = {
        title: record.title,
        src: record.path,
        path: record.path,
      };
    }

    return record && (record.created_by === userId || canEdit) ? (
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Edit Expense
        </Typography>
        <Box display={{ sx: "block", sm: "flex", width: "100%" }}></Box>
        {canEdit ? (
          <>
            <ReferenceInput source="created_by" reference="users">
              <SelectInput
                label="Created By"
                optionText={renderCreatedBy}
                fullWidth
                validate={[required()]}
              />
            </ReferenceInput>
          </>
        ) : (
          <></>
        )}

        <SelectInput
          label="Category"
          source="category"
          choices={expenseCategoryChoices}
          optionText="label"
          fullWidth
          validate={[required()]}
        />
        <SelectInput
          label="Payment"
          source="payment"
          choices={expensePaymentChoices}
          optionText="label"
          fullWidth
          validate={[required()]}
        />
        <DateInput source="paid_at" fullWidth validate={[required()]} />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              label="Currency"
              source="currency"
              choices={expenseCurrencyChoices}
              optionText="label"
              fullWidth
              validate={[required()]}
            />
          </Box>
          <Box flex={3} ml={{ xs: 0, sm: "0.5em" }}>
            <NumberInput
              label="Total"
              source="total"
              min={0}
              fullWidth
              validate={[required(), minValue(0)]}
            />
          </Box>
        </Box>
        <NumberInput
          label="Total (HKD)"
          source="amount"
          min={0}
          fullWidth
          validate={[minValue(0)]}
        />
        <FileInput label="Receipt" source="receipt">
          <MyFileField source="src" title="title" />
        </FileInput>
        <TextInput source="note" multiline fullWidth />
        {canEdit ? (
          <SelectInput
            label="Status"
            source="status"
            choices={expenseStatusChoices}
            optionText="label"
            fullWidth
            validate={[required()]}
          />
        ) : (
          <></>
        )}
      </SimpleForm>
    ) : (
      <Box
        display={{ xs: "block", sm: "flex", width: "100%", height: "400px" }}
      >
        <Typography variant="h4" gutterBottom>
          Sorry, you are not allowed to edit it.
        </Typography>
      </Box>
    );
  };

  return (
    <Edit title="Edit expense">
      <EditContent />
    </Edit>
  );
};

const ExpenseEdit = () => {
  const { isLoading: isLoadingPermissions, permissions } = usePermissions();
  const { data: identity, isLoading: isLoadingIdentity } = useGetIdentity();

  if (isLoadingPermissions || isLoadingIdentity) return <></>;

  return <ExpenseEditReal permissions={permissions} identity={identity} />;
};

export default ExpenseEdit;
