import * as React from "react";
import { useState } from "react";
import { usePermissions } from "react-admin";
import Box from "@mui/material/Box";

import { TiWeatherPartlySunny } from "react-icons/ti";
import { RiAdminFill } from "react-icons/ri";

import { DashboardMenuItem, MenuItemLink, useSidebarState } from "react-admin";

import users from "../users";
import roles from "../roles";
import expenses from "../expenses";
import hktemp from "../hktemp";
import hkrainfall from "../hkrainfall";
import hkhumidity from "../hkhumidity";
import hklightning from "../hklightning";
import hkwarnsum from "../hkwarnsum";

// Testing
import dummys from "../dummy";

import SubMenu from "./SubMenu";

const Menu = ({ dense = false }) => {
  const { isLoading, permissions } = usePermissions();
  const [state, setState] = useState({
    menuHKWeather: true,
    menuAdmin: true,
  });
  const [open] = useSidebarState();

  const menuAdminList = [
    {
      key: "sub_admin",
      type: "submenu",
      handleToggle: "menuAdmin",
      state: state.menuAdmin,
      name: "Admin",
      icon: <RiAdminFill />,
      item: [
        {
          key: "admin_users",
          to: "/users",
          primaryText: users.label,
          leftIcon: <users.icon />,
        },
        {
          key: "admin_roles",
          to: "/roles",
          primaryText: roles.label,
          leftIcon: <roles.icon />,
        },
      ],
    },
  ];

  const menuList = [
    {
      key: "dummy",
      to: "/dummy",
      primaryText: dummys.label,
      leftIcon: <dummys.icon />,
    },
    {
      key: "expenses",
      to: "/expenses",
      primaryText: expenses.label,
      leftIcon: <expenses.icon />,
    },

    {
      key: "sub_hkweather",
      type: "submenu",
      handleToggle: "menuHKWeather",
      state: state.menuHKWeather,
      name: "HK Weather",
      icon: <TiWeatherPartlySunny />,

      item: [
        {
          key: "hktemp",
          to: "/hktemp",
          primaryText: hktemp.label,
          leftIcon: <hktemp.icon />,
        },
        {
          key: "rainfall",
          to: "/hkrainfall",
          primaryText: hkrainfall.label,
          leftIcon: <hkrainfall.icon />,
        },
        {
          key: "humidity",
          to: "/hkhumidity",
          primaryText: hkhumidity.label,
          leftIcon: <hkhumidity.icon />,
        },
        {
          key: "lightning",
          to: "/hklightning",
          primaryText: hklightning.label,
          leftIcon: <hklightning.icon />,
        },
        {
          key: "warnsum",
          to: "/hkwarnsum",
          primaryText: hkwarnsum.label,
          leftIcon: <hkwarnsum.icon />,
        },
      ],
    },
  ];

  const createMenu = (menuList) => {
    return menuList.map((e) =>
      e.type && e.type === "submenu" ? (
        <SubMenu
          key={e.key}
          handleToggle={() => handleToggle(e.handleToggle)}
          isOpen={e.state}
          name={e.name}
          icon={e.icon}
          dense={dense}
        >
          {e.item &&
            e.item.map((se) => (
              <MenuItemLink
                key={se.key}
                to={se.to}
                state={{ _scrollToTop: true }}
                primaryText={se.primaryText}
                leftIcon={se.leftIcon}
                dense={dense}
              />
            ))}
        </SubMenu>
      ) : (
        <MenuItemLink
          key={e.key}
          to={e.to}
          state={{ _scrollToTop: true }}
          primaryText={e.primaryText}
          leftIcon={e.leftIcon}
          dense={dense}
        />
      )
    );
  };

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return isLoading ? (
    <>Loading</>
  ) : (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      {createMenu(menuList)}
      {permissions.role === "admin" && createMenu(menuAdminList)}
    </Box>
  );
};

export default Menu;
