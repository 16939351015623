import { IoSettings } from "react-icons/io5";

import RoleList from "./RoleList";
import RoleEdit from "./RoleEdit";
import RoleCreate from "./RoleCreate";

const resource = {
  label: "Settings",
  list: RoleList,
  create: RoleCreate,
  edit: RoleEdit,
  icon: IoSettings,
};

export default resource;
