import { BsLightningChargeFill } from "react-icons/bs";

import HkLightningList from "./HkLightningList";

const resource = {
  label: "Lightning",
  list: HkLightningList,
  icon: BsLightningChargeFill,
};

export default resource;
