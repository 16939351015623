import * as React from "react";
import {
  List,
  Datagrid,
  EmailField,
  TextField,
  ReferenceField,
  TopToolbar,
  CreateButton,
  ExportButton,
  SearchInput,
} from "react-admin";

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const userFilters = [<SearchInput source="q" alwaysOn />];

const UserList = () => (
  <List
    sort={{ field: "email", order: "ASC" }}
    perPage={25}
    filters={userFilters}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit">
      <EmailField label="Email" source="email" />
      <TextField label="First Name" source="firstname" />
      <TextField label="Last Name" source="lastname" />
      <ReferenceField label="Role" source="role_id" reference="roles">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default UserList;
