import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  CreateButton,
  ExportButton,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  SelectField,
  NumberField,
  useGetList,
  useGetIdentity,
  usePermissions,
  EditButton,
  DeleteButton,
  WrapperField,
  SelectInput,
  DateInput,
} from "react-admin";

import {
  expenseCategoryChoices,
  expenseCurrencyChoices,
  expenseStatusChoices,
} from "./expenseType";

import {
  acCanEdit,
  acCanCreate,
  acCanDelete,
  acCanShow,
} from "../components/accessControl";

import MyFileField from "../components/MyFileField";

const ExpenseAction = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const ExpenseListReal = (props) => {
  const { permissions, identity } = props;

  const { id: userId } = identity;
  const canShow = acCanShow(permissions);
  const canEdit = acCanEdit(permissions);
  const canCreate = acCanCreate(permissions);
  const canDelete = acCanDelete(permissions);
  const [userListSelect, setUserListSelect] = useState([]);

  const userFilter = canShow ? {} : { id: userId };
  const { data: userList, isLoading: isLoadingUser } = useGetList("users", {
    pagination: { page: 1, perPage: -1 },
    filter: userFilter,
  });

  useEffect(() => {
    if (userList) {
      setUserListSelect(
        userList.map((user) => {
          return { id: user.id, name: `${user.firstname} ${user.lastname}` };
        })
      );
    }
  }, [userList]);

  const expenseFilters = [
    <SelectInput
      label="Category"
      source="category"
      choices={expenseCategoryChoices}
      optionText="label"
      alwaysOn
    />,
    <DateInput label="From" source="paid_at_gte" alwaysOn />,
    <DateInput label="to" source="paid_at_lte" alwaysOn />,
    <SelectInput
      label="Status"
      source="status"
      choices={expenseStatusChoices}
      optionText="label"
      alwaysOn
    />,
  ];
  canShow &&
    expenseFilters.unshift(
      <SelectInput
        label="Created By"
        source="created_by"
        choices={userListSelect}
        alwaysOn
      />
    );

  const expenseFilter = canShow ? {} : { created_by: userId };

  return (
    <List
      sort={{ field: "paid_at", order: "DESC" }}
      perPage={25}
      filters={expenseFilters}
      filter={expenseFilter}
      actions={<ExpenseAction />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="ID" source="id" />
        <ReferenceField source="created_by" reference="users">
          <FunctionField
            label="Created By"
            render={(record) => `${record.firstname} ${record.lastname}`}
          />
        </ReferenceField>
        <SelectField
          label="Category"
          source="category"
          choices={expenseCategoryChoices}
          optionText="label"
        />
        <SelectField
          label="Currency"
          source="currency"
          choices={expenseCurrencyChoices}
          optionText="label"
        />
        <NumberField label="Total" source="total" />
        <DateField label="Paid At" source="paid_at" />
        <SelectField
          label="Status"
          source="status"
          choices={expenseStatusChoices}
          optionText="label"
        />
        <WrapperField label="Receipt">
          <MyFileField source="path" title="title" />
        </WrapperField>
        <WrapperField label="Actions">
          <FunctionField
            render={(record) =>
              record.created_by === userId || canEdit ? <EditButton /> : <></>
            }
          />
          <FunctionField
            render={(record) =>
              record.created_by === userId || canDelete ? (
                <DeleteButton />
              ) : (
                <></>
              )
            }
          />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

const ExpenseList = () => {
  const { isLoading: isLoadingPermissions, permissions } = usePermissions();
  const { data: identity, isLoading: isLoadingIdentity } = useGetIdentity();

  if (isLoadingPermissions || isLoadingIdentity) return <></>;

  return <ExpenseListReal permissions={permissions} identity={identity} />;
};

export default ExpenseList;
